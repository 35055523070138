import React from "react"
import Layout from "../components/layout"
import ImageContainer from "../components/imageContainer"
import Personpc from "../images/personpc.png"
import Cloud from "../images/cloud.png"
import Conection from "../images/conectionpeople.png"
import RocketPc from "../images/rocketpc.png"

import Personmb from "../images/personmb.png"
import Cloudmb from "../images/cloudmb.png"
import Conectionmb from "../images/conectionpeoplemb.png"
import Rocketmb from "../images/rocketmb.png"

import Rocket from "../images/rocket.png"
import Layers from "../images/layers.png"
import Code from "../images/code.png"
import Lock from "../images/lock.png"
import ButtonHB from "../components/buttonHB"
import BgAbout from "../components/bgAbout"

const Style = {
  SolutionSection: {
    all: "container relative ",
    small: "px-5 pt-40 sm:pt-24  sm:px-6 md:px-5 ",
    large: "lg:pt-40 lg:px-24 xl:px-20",
  },
  SolutionTitle: {
    all: "frk-bold text-primary text-2xl ",
    small: "sm:text-3xl",
    large: "lg:text-6xl", //2xl:text-7xl
  },
  SolutionConteinerCards: {
    all: "w-full mt-6 sm:mt-8 sm:flex sm:flex-wrap sm:justify-between ",
  },
  SolutionCard: {
    small: "shadow pb-5 mb-5 w-full flex flex-col  sm:w-48p", 
    large: "xl:mb-12",
  },
  SolutionCardTitle: {
    all: "frk-bold text-primary ",
    small: "mt-4 px-2 text-2xl",
    large: "lg:px-4 lg:h-auto lg:text-2xl  xl:px-6 xl:h-8",
  },
  SolutionCardText: {
    all: "frk-regular text-secondary  ",
    small: "text-lg mt-1 px-2 ",
    large: "lg:px-4 xl:px-6 xl:mt-0",
  },
  SolutionCardButtonDiv: {
    small: "px-2 w-full sm:w-48p h-auto flex flex-1 self-end mt-4 sm:mt-1 ",
    large: " xl:px-6",
  },
  SolutionCardButtonText: {
    small: " w-full flex-1 self-end",
    large: "",
  },
  OurProcessBg: {
    all: "bg-aqua pb-6",
  },
  OurProcessSection: {
    all: "container ",
    small: "px-5 mt-10 sm:px-6 md:px-5", 
    large: "lg:px-24 xl:mt-16 xl:px-20",
  },
  OurProcessTitle: {
    all: "frk-bold text-primary ",
    small: "text-3xl text-center pt-8 sm:pt-10",
    large: "",
  },
  OurProcessConteinerCards: {
    all: "w-full sm:flex sm:flex-wrap sm:justify-between sm:pb-8",
  },
  OurProcessCard: {
    small: "shadow py-5 my-10 bg-white text-center flex flex-col sm:mb-2 sm:w-48p  ",
    large: "xl:w-23p ",
  },
  OurProcessCardImgDiv: {
    all: "w-full  mt-8 sm:px-24 lg:px-20 ",
  },
  OurProcessCardImg: {
    all: "mx-auto h-16 sm:h-20  object-cover ", //w-full
  },
  OurProcessCardDivText: {
    all: "px-2 ",
  },
  OurProcessCardTitle: {
    small: "frk-bold text-primary text-2xl mt-4 ",
    large: "",
  },
  OurProcessCardText: {
    all: "frk-regular text-secondary",
    small: " text-lg mt-2 mb-6",
    large: "",
  },
}

const SolutionCard = ({ imgXl, imgMb, alt, title, link, description }) => {
  return (
    <div className={`${Style.SolutionCard.small} ${Style.SolutionCard.large}`}>
      <ImageContainer
        container="w-full hidden xl:block"
        src={imgXl}
        alt={alt}
      />
      <ImageContainer container="w-full xl:hidden" src={imgMb} alt={alt} />
      <h3
        className={`${Style.SolutionCardTitle.all}  ${Style.SolutionCardTitle.small} ${Style.SolutionCardTitle.large}`}
      >
        {title}
      </h3>
      <p
        className={`${Style.SolutionCardText.all}  ${Style.SolutionCardText.small} ${Style.SolutionCardText.large}`}
      >
        {description}
      </p>
      <div
        className={`${Style.SolutionCardButtonDiv.small} ${Style.SolutionCardButtonDiv.large}`}
      >
        <ButtonHB
          type="link"
          text="Read More"
          className={`${Style.SolutionCardButtonText.all} ${Style.SolutionCardButtonText.small} ${Style.SolutionCardButtonText.large}`}
          to={`/solutions${link}`}
        />
      </div>
    </div>
  )
}

const OurProcessCard = ({ img, alt, title, description }) => {
  return (
    <div
      className={`${Style.OurProcessCard.small} ${Style.OurProcessCard.large}`}
    >
      <ImageContainer
        container={`${Style.OurProcessCardImgDiv.all}`}
        src={img}
        alt={alt}
        className={Style.OurProcessCardImg.all}
      />
      <div className={Style.OurProcessCardDivText.all}>
        <h3
          className={`${Style.OurProcessCardTitle.small} ${Style.OurProcessCardTitle.large}`}
        >
          {title}
        </h3>
        <p
          className={`${Style.OurProcessCardText.all} ${Style.OurProcessCardText.small} ${Style.OurProcessCardText.large}`}
        >
          {description}
        </p>
      </div>
    </div>
  )
}

const Solutions = () => {
  return (
    <Layout title="Solutions" link="/solutions">
      {/*<BgSolutions />*/}
      <BgAbout />
      <section
        className={`${Style.SolutionSection.all} ${Style.SolutionSection.small} ${Style.SolutionSection.large}`}
      >
        <h1
          className={`${Style.SolutionTitle.all} ${Style.SolutionTitle.small} ${Style.SolutionTitle.large}`}
        >
          Solutions
        </h1>
        <div className={`${Style.SolutionConteinerCards.all}`}>
          <SolutionCard
            imgXl={Personpc}
            imgMb={Personmb}
            alt="PersonPc"
            title="Digital Transformation Solutions"
            link="/digital-transformation-solutions"
            description="Digital transformation is a real trend that goes beyond keyword bingo. 
            Companies need to evolve faster than before in a world where…"
          />
          <SolutionCard
            imgXl={Cloud}
            imgMb={Cloudmb}
            alt="cloud"
            title="Cloud Operational Excellence & Upgrades"
            link="/cloud-operational"
            description="Cloud operations are common across modern enterprises. From simple SaaS 
            offerings that are crucial to business processes to cloud-native…."
          />
          <SolutionCard
            imgXl={Conection}
            imgMb={Conectionmb}
            alt="conection"
            title="Enterprise Development"
            link="/enterpise-development"
            description='Modern enterprises often identify needs that go beyond "off-the-shelf" products. Once a problem is identified and solutions leave gaps…'
          />
          <SolutionCard
            imgXl={RocketPc}
            imgMb={Rocketmb}
            alt="rocketpc"
            title="Startup Platform Development"
            link="/startup-platform-development"
            description="As a startup you have your idea, your unique concept. Transforming it into 
            a product requires technology guidance and experience. A poorly planned product can turn into a business…"
          />
        </div>
      </section>
      <section className={`${Style.OurProcessBg.all}`}>
        <div
          className={`${Style.OurProcessSection.all} ${Style.OurProcessSection.small} ${Style.OurProcessSection.large}`}
        >
          <h2
            className={`${Style.OurProcessTitle.all} ${Style.OurProcessTitle.small} ${Style.OurProcessTitle.large}`}
          >
            OUR PROCESS
          </h2>
          <div className={`${Style.OurProcessConteinerCards.all}`}>
            <OurProcessCard
              img={Rocket}
              alt="rocket"
              title="Discovery"
              description="The hardest part is figuring out what to build, not building it. 
              We call our initial phase “Discovery”, where we translate your vision into a 
              full fledged product roadmap and strategy."
            />
            <OurProcessCard
              img={Layers}
              alt="layers"
              title="User Experience"
              description="The best user experiences make interactions feel natural. First, 
              we analyze all aspects of UX, in all screens and devices. Then, you receive a 
              design that, besides beautiful, is fine-tuned for a solid user experience."
            />
            <OurProcessCard
              img={Code}
              alt="code"
              title="Development"
              description="We follow our flexible process to help us deliver multi channel 
              applications, using the most efficient stacks that rapidly enables going from 
              MVP to full featured release."
            />
            <OurProcessCard
              img={Lock}
              alt="lock"
              title="Tech Audit"
              description="Automation and clearly defined processes give you the edge. 
              We assess your current development and tech operations process to deliver 
              a report with clear findings to improve it, with emphasis on automation and savings."
            />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Solutions
